/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Button, Text, Image } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-15kbrt0 --style3 js-anim  --anim7 --anim-s5 --full pb--10" anim={"7"} name={"wstęp"} animS={"5"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/133/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/133/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/133/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/133/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/133/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/133/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/133/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/133/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5" anim={"6"} animS={"5"}>
              
              <Subtitle className="subtitle-box fs--30" content={"<span style=\"color: white;\">Agent nieruchomości Warszawa</span>"}>
              </Subtitle>

              <Title className="title-box fs--128 lh--1 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: white;\">Piotr Wieczorkowski</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape3 fs--20" content={"Oferta usług"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"usługi"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s2" anim={"2"} animS={"2"}>
              
              <Title className="title-box" content={"Ponad 12 lat doświadczenia w branży nieruchomości"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":800}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--4 mb--10 mt--40 pr--08" anim={"2"} animS={"2"} style={{"maxWidth":1000}} columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-5.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(177,37,37,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Kalkulacja cen"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-3.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(177,37,37,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Analiza statystyczna rynku"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-4.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(177,37,37,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Korzystne konrakty"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-2.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(177,37,37,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Stawki procentowe"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"rekomendacje"} style={{"backgroundColor":"var(--color-custom-1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: white;\">Rekomendacje</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s2 --left" anim={"4"} animS={"2"}>
              
              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\">\"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach.\"</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: white; font-weight: bold;\">—   Anna Nowak&nbsp; &nbsp; ⭐ ⭐ ⭐ ⭐ ⭐&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s2 --left" anim={"4"} animS={"2"}>
              
              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\">\"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach.\"</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: white; font-weight: bold;\">—   Anna Nowak&nbsp; &nbsp; ⭐ ⭐ ⭐ ⭐ ⭐&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1n1007i pb--80 pt--80" name={"kontakt"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/133/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/133/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/133/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/133/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/133/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/133/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/133/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/133/fullscreen-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Umów spotkanie</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape3" content={"+48 797 811 2X0"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"stopka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Piotr Wieczorkowski</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">O mnie</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"+48 797 811 2X0<br>info@twoje-stronyy.com"}>
              </Text>

              <Text className="text-box" content={"REGON: 12345678<br>Utworzone przez<a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}